import "D:\\Source\\Repos\\WallinServicesGui\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\Source\\Repos\\WallinServicesGui\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\Source\\Repos\\WallinServicesGui\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\Source\\Repos\\WallinServicesGui\\node_modules\\core-js\\modules\\es.promise.finally.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/it";
import filters from "./filters";
import Notifications from "vue-notification"; // Import the Auth0 configuration

import { domain, clientId } from "../auth_config.json"; // Import the plugin here

import { Auth0Plugin } from "./auth";
dayjs.locale("it");
filters.forEach(function (f) {
  Vue.filter(f.name, f.execute);
}); // Install the authentication plugin here

Vue.use(Auth0Plugin, {
  domain: domain,
  clientId: clientId,
  onRedirectCallback: function onRedirectCallback(appState) {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  }
});
Vue.use(Notifications);
axios.defaults.headers.common["Accept"] = "application/json";
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
new Vue({
  store: store,
  vuetify: vuetify,
  router: router,
  render: function render(h) {
    return h(App);
  }
}).$mount("#app");