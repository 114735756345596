import Vue from "vue";
import VueRouter from "vue-router";

import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/social",
    name: "Social",
    component: () => import("../views/Social.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/social/:id/messages",
    props: (route) => ({ id: route.params.id }),
    name: "Messages",
    component: () => import("../views/Messages.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/News.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/wallsignlogger",
    name: "WallSign Logger",
    component: () => import("../views/WallSignLogger.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/newsapi",
    name: "News API",
    component: () => import("../views/NewsApi.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/news/:id/articles",
    props: (route) => ({ id: route.params.id }),
    name: "Articles",
    component: () => import("../views/Articles.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/newsapi/:id/articles",
    props: (route) => ({ id: route.params.id }),
    name: "NewsApiArticles",
    component: () => import("../views/NewsApiArticles.vue"),
    beforeEnter: authGuard,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
