import costants from "../utils/costants";
import util from "../utils/helpers";
export default [{
  name: "date",
  execute: util.formatDate
}, {
  name: "newsType",
  execute: function execute(value) {
    if (value === undefined || value === null) {
      return null;
    }

    return costants.NewsTypes[value]["text"];
  }
}, {
  name: "socialType",
  execute: function execute(value) {
    if (value === undefined || value === null) {
      return null;
    }

    return costants.SocialTypes[value]["text"];
  }
}, {
  name: "feedStatus",
  execute: function execute(value) {
    if (value === undefined || value === null) {
      return null;
    }

    return costants.FeedStatus[value]["text"];
  }
}];