import Vue from "vue";

export default {
  getWallSignTenants: () => {
    var url = `${process.env.VUE_APP_WALLSIGN_GETTENANTS_URL}`;
    return Vue.prototype.$axios.get(url);
  },
  getWallinOneUsers: () => {
    var url = `${process.env.VUE_APP_WALLINONE_GETUSERS_URL}`;
    return Vue.prototype.$axios.get(url);
  },
  getSmartiketteUsers: () => {
    var url = `${process.env.VUE_APP_SMARTIKETTE_GETUSERS_URL}`;
    return Vue.prototype.$axios.get(url);
  },
};
