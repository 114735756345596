<template>
  <app-loading v-if="appLoading" />
  <v-app v-else>
    <notifications />
    <v-navigation-drawer app>
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img src="https://cdn.wallin.tv/wallin/icon.png" />
          </v-list-item-avatar>

          <v-list-item-content v-if="$auth.isAuthenticated">
            <v-list-item-title>{{ $auth.user.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ $auth.user.email }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content v-else>
            <v-list-item-title>Wallin Aggregators</v-list-item-title>
            <v-list-item-subtitle>Please log in</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/social">
          <v-list-item-icon>
            <v-icon>mdi-facebook</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Social</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/news">
          <v-list-item-icon>
            <v-icon>mdi-newspaper-variant-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>News</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/newsapi">
          <v-list-item-icon>
            <v-icon>mdi-api</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>News API</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/wallsignlogger">
          <v-list-item-icon>
            <v-icon>mdi-console</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>WallSign Logger</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <authentication-button class="pa-2"></authentication-button>
      </template>
    </v-navigation-drawer>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container class="fill-height" fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app padless fixed>
      <v-col class="text-left" cols="6">
        Copyright &copy;
        {{ new Date().getFullYear() }} —
        <strong>Wallin Srl</strong>
      </v-col>
      <v-col class="text-right" cols="6">
        Made with
        <v-icon color="red" size="17">mdi-heart</v-icon>
        &nbsp;in Italy
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {
    AuthenticationButton: () => import("@/components/AuthenticationButton"),
    AppLoading: () => import("@/views/AppLoading"),
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    appLoading: function () {
      var isAuthLoading = !this.$auth.isAuthenticated || this.$auth.isLoading;
      return isAuthLoading || !this.loaded;
    },
  },
  mounted() {
    var promises = [];
    promises.push(this.$store.dispatch("loadWallsignTenants"));
    promises.push(this.$store.dispatch("loadWallinOneUsers"));
    // promises.push(this.$store.dispatch("loadSmartiketteUsers"));
    promises.push(this.$store.dispatch("loadNewsApiStatus"));

    Promise.allSettled(promises).then(() => {
      this.loaded = true;
    });
  },
  methods: {
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>

<style lang="scss"></style>
