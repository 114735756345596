import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);
dayjs.extend(utc);
export default {
  formatDate: function formatDate(value, format) {
    if (!value) {
      return null;
    }

    if (!format) {
      format = "LLL";
    }

    return dayjs.utc(value).local().format(format);
  }
};