import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import Vue from "vue";
import store from "@/store";
export default {
  getList: function getList(options) {
    var url = "".concat(store.getters.newsUrl, "/api/Feeds/Platform/").concat(store.state.currentPlatformId, "?pageNumber=").concat(options.pageNumber); //build url with params

    if (options.pageSize) {
      url += "&pageSize=".concat(options.pageSize);
    }

    if (options.search) {
      url += "&search=".concat(options.search);
    }

    if (options.owner) {
      url += "&ownerId=".concat(options.owner);
    }

    if (options.newsType !== null) {
      url += "&newsType=".concat(options.newsType);
    }

    if (options.status !== null) {
      url += "&status=".concat(options.status);
    }

    if (options.enabled !== null) {
      url += "&enabled=".concat(options.enabled);
    }

    if (options.sortBy) {
      url += "&sortBy=".concat(options.sortBy);
    }

    if (options.sortDescending) {
      url += "&sortDescending=".concat(options.sortDescending);
    }

    console.log(url);
    return Vue.prototype.$axios.get(url, {
      headers: {
        "x-functions-key": store.getters.newsKey
      }
    });
  },
  getNewsList: function getNewsList(id, options) {
    var url = "".concat(store.getters.newsUrl, "/api/News/").concat(id, "?pageNumber=").concat(options.pageNumber);

    if (options.pageSize) {
      url += "&pageSize=".concat(options.pageSize);
    }

    if (options.search) {
      url += "&search=".concat(options.search);
    }

    return Vue.prototype.$axios.get(url, {
      headers: {
        "x-functions-key": store.getters.newsKey
      }
    });
  },
  startDownload: function startDownload(id) {
    return Vue.prototype.$axios.post("".concat(store.getters.newsUrl, "/api/Download/").concat(id), null, {
      headers: {
        "x-functions-key": store.getters.newsKey
      }
    });
  },
  getFeed: function getFeed(id) {
    return Vue.prototype.$axios.get("".concat(store.getters.newsUrl, "/api/Feeds/").concat(id), {
      headers: {
        "x-functions-key": store.getters.newsKey
      }
    });
  },
  getNewsApiStatus: function getNewsApiStatus() {
    return Vue.prototype.$axios.get("".concat(process.env.VUE_APP_NEWSAPI_BASE_URL, "/api/Sources/List?includeStatus=true&code=").concat(process.env.VUE_APP_NEWSAPI_KEY));
  },
  startDownloadNewsApi: function startDownloadNewsApi(id) {
    return Vue.prototype.$axios.post("".concat(process.env.VUE_APP_NEWSAPI_BASE_URL, "/api/Download/").concat(id, "?code=").concat(process.env.VUE_APP_NEWSAPI_KEY));
  },
  getNewsApiFeed: function getNewsApiFeed(id) {
    return Vue.prototype.$axios.get("".concat(process.env.VUE_APP_NEWSAPI_BASE_URL, "/api/Source/").concat(id, "?code=").concat(process.env.VUE_APP_NEWSAPI_KEY));
  },
  getNewsInfo: function getNewsInfo() {
    return Vue.prototype.$axios.get("".concat(process.env.VUE_APP_NEWS_BASE_URL, "/api/Info?json=true"), {
      headers: {
        "x-functions-key": process.env.VUE_APP_NEWS_KEY
      }
    });
  },
  getNewsApiInfo: function getNewsApiInfo() {
    return Vue.prototype.$axios.get("".concat(process.env.VUE_APP_NEWSAPI_BASE_URL, "/api/Info?json=true"), {
      headers: {
        "x-functions-key": process.env.VUE_APP_NEWSAPI_KEY
      }
    });
  }
};