export default {
  NewsTypes: [{
    value: 0,
    text: "GeoNews"
  }, {
    value: 1,
    text: "RSS"
  }, {
    value: 2,
    text: "NewsAPI"
  }],
  SocialTypes: [{
    value: 0,
    text: "Facebook"
  }, {
    value: 1,
    text: "Instagram"
  }, {
    value: 2,
    text: "Twitter"
  }, {
    value: 3,
    text: "Linkedin"
  }],
  FeedStatus: [{
    value: 0,
    text: "None"
  }, {
    value: 1,
    text: "Pending"
  }, {
    value: 2,
    text: "Downloading"
  }, {
    value: 3,
    text: "Error"
  }]
};