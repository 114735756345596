import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import PlatformService from "@/services/PlatformService";
import NewsService from "@/services/NewsService";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  storage: window.localStorage,
  reducer: (state) => ({
    currentPlatformId: state.currentPlatformId,
  }),
});

// initial state
const state = {
  platforms: [
    {
      id: "wallsign",
      name: "WallSign",
      newsUrl: process.env.VUE_APP_NEWS_BASE_URL,
      newsKey: process.env.VUE_APP_NEWS_KEY,
      socialUrl: process.env.VUE_APP_WALLSIGN_SOCIAL_BASE_URL,
      socialKey: process.env.VUE_APP_WALLSIGN_SOCIAL_KEY,
    },
    {
      id: "wallinone",
      name: "Wallin ONE",
      newsUrl: process.env.VUE_APP_NEWS_BASE_URL,
      newsKey: process.env.VUE_APP_NEWS_KEY,
      socialUrl: process.env.VUE_APP_WALLINONE_SOCIAL_BASE_URL,
      socialKey: process.env.VUE_APP_WALLINONE_SOCIAL_KEY,
    },
    {
      id: "smartikette",
      name: "Smartikette",
      newsUrl: process.env.VUE_APP_NEWS_BASE_URL,
      newsKey: process.env.VUE_APP_NEWS_KEY,
      socialUrl: process.env.VUE_APP_SMARTIKETTE_SOCIAL_BASE_URL,
      socialKey: process.env.VUE_APP_SMARTIKETTE_SOCIAL_KEY,
    },
  ],
  wallsignTenants: {},
  wallinOneUsers: {},
  smartiketteUsers: {},
  newsApiStatus: [],
  currentPlatformId: null,
};

// getters
const getters = {
  ownerList: function (state) {
    if (!state.currentPlatformId) {
      return [];
    }

    let source = {};
    switch (state.currentPlatformId) {
      case "wallsign":
        source = state.wallsignTenants;
        break;

      case "wallinone":
        source = state.wallinOneUsers;
        break;

      case "smartikette":
        source = state.smartiketteUsers;
        break;
    }

    return Object.keys(source)
      .map((id) => {
        return {
          value: id,
          text: source[id],
        };
      })
      .sort((a, b) => {
        var textA = a.text.toUpperCase(); // ignore upper and lowercase
        var textB = b.text.toUpperCase(); // ignore upper and lowercase
        if (textA < textB) {
          return -1;
        }
        if (textA > textB) {
          return 1;
        }

        return 0;
      });
  },
  currentPlatform: function (state) {
    var platform = state.platforms.find(
      (p) => p.id === state.currentPlatformId
    );

    if (!platform) {
      return null;
    }

    return platform;
  },
  newsUrl: (state, getters) => {
    if (!getters.currentPlatform) {
      return null;
    }
    return getters.currentPlatform.newsUrl;
  },
  newsKey: (state, getters) => {
    if (!getters.currentPlatform) {
      return null;
    }
    return getters.currentPlatform.newsKey;
  },

  socialUrl: (state, getters) => {
    if (!getters.currentPlatform) {
      return null;
    }
    return getters.currentPlatform.socialUrl;
  },
  socialKey: (state, getters) => {
    if (!getters.currentPlatform) {
      return null;
    }
    return getters.currentPlatform.socialKey;
  },
};

// actions
const actions = {
  loadWallsignTenants: ({ commit }) => {
    return PlatformService.getWallSignTenants().then((result) => {
      commit("SET_WALLSIGN_TENANTS", result.data);
      return result.data;
    });
  },
  loadWallinOneUsers: ({ commit }) => {
    return PlatformService.getWallinOneUsers().then((result) => {
      commit("SET_WALLINONE_USERS", result.data);
      return result.data;
    });
  },
  loadSmartiketteUsers: ({ commit }) => {
    return PlatformService.getSmartiketteUsers().then((result) => {
      commit("SET_SMARTIKETTE_USERS", result.data);
      return result.data;
    });
  },
  loadNewsApiStatus: ({ commit }) => {
    return NewsService.getNewsApiStatus().then((result) => {
      commit("SET_NEWSAPI_STATUS", result.data);
      return result.data;
    });
  },
  setCurrentPlatformId: ({ commit }, platformId) => {
    commit("SET_CURRENT_PLATFORM_ID", platformId);
  },
};

const mutations = {
  SET_WALLSIGN_TENANTS: (state, tenants) => {
    Vue.set(state, "wallsignTenants", tenants);
  },
  SET_WALLINONE_USERS: (state, users) => {
    Vue.set(state, "wallinOneUsers", users);
  },
  SET_SMARTIKETTE_USERS: (state, users) => {
    Vue.set(state, "smartiketteUsers", users);
  },
  SET_NEWSAPI_STATUS: (state, newsapi) => {
    Vue.set(state, "newsApiStatus", [...newsapi]);
  },
  SET_CURRENT_PLATFORM_ID: (state, platformId) => {
    state.currentPlatformId = platformId;
  },
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  plugins: [vuexLocal.plugin],
});
